import Button, { SizeType } from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { defineMessage, Trans } from '@lingui/macro'
import MsgTrans from '@components/common/i18n/message-trans'
import BookFreeClassButton from '@components/common/book-free-class-btn'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { LinguiJSMessageDescriptor } from '@lib/types/common'

export type StatisticsProps = {
  title: string
  description: LinguiJSMessageDescriptor
}

const STATISTICS: StatisticsProps[] = [
  {
    title: '🎓 300+',
    description: defineMessage({
      message: 'graduate IT Instructors from India',
    }),
  },
  {
    title: '👨‍💻 7+',
    description: defineMessage({
      message: 'Years coding experience',
    }),
  },
  {
    title: '👩 91%',
    description: defineMessage({
      message: 'Female teachers',
    }),
  },
  {
    title: '💻 10+',
    description: defineMessage({
      message: 'Coding languages',
    }),
  },
]

type Props = {
  title?: string | ReactNode
  isCoursePage?: boolean
  shouldScrollToTop?: boolean
  isShowBecomeTeacher?: boolean
  ctaSize?: SizeType
  urlQuery?: string
  ctaText?: string
  ctaLink?: string
  statistics?: StatisticsProps[]
  imagePath?: string
}

const defaultTitle = (
  <>
    <Trans id="">
      Learn coding from the{' '}
      <span className="yellow-underline">experienced teachers.</span> Our
      hand-picked coding teachers ensure the best learning experience.
    </Trans>
  </>
)

const Teachers = ({
  title = defaultTitle,
  isCoursePage = false,
  shouldScrollToTop,
  isShowBecomeTeacher = true,
  ctaSize = 'xl',
  urlQuery,
  ctaText,
  ctaLink,
  statistics,
  imagePath,
}: Props) => {
  const statisticsContent = statistics || STATISTICS
  return (
    <div className="container mx-auto py-16 px-5 text-grey">
      {isCoursePage && (
        <h2 className="text-2xl font-700 leading-tight mb-8">{title}</h2>
      )}
      <div className="flex flex-col items-center lg:flex-row gap-x-10 justify-between leading-tight mb-12">
        <div className="hidden lg:block">
          <FastlyImage
            src={`${
              imagePath
                ? `${imagePath}.png`
                : '/images/locale-images/us/teachers/teachers-v2.png'
            }`}
            webpSrc={`${
              imagePath
                ? `${imagePath}.webp`
                : '/images/locale-images/us/teachers/teachers-v2.webp'
            }`}
            height={426}
            width={471}
            alt="Codingal teachers"
          />
        </div>

        <div className="flex flex-col space-y-12">
          {!isCoursePage && (
            <h2 className="text-center lg:text-left text-2xl lg:text-4xl font-600 max-w-2xl">
              {title}
            </h2>
          )}

          <div className="flex flex-wrap justify-center lg:justify-start lg:flex-row gap-3">
            {statisticsContent.map((stat, i) => (
              <div
                key={i}
                className="bg-orange-200 p-3 md:p-5 text-xl w-40 flex flex-col rounded-md"
                style={{ maxWidth: '46%' }}
              >
                <p className="text-2xl font-700 pb-2">{stat.title}</p>
                <MsgTrans msg={stat.description} />
              </div>
            ))}
          </div>

          <div className="lg:hidden">
            <FastlyImage
              src={`${
                imagePath
                  ? `${imagePath}.png`
                  : '/images/locale-images/us/teachers/teachers-v2.png'
              }`}
              webpSrc={`${
                imagePath
                  ? `${imagePath}.webp`
                  : '/images/locale-images/us/teachers/teachers-v2.webp'
              }`}
              height={500}
              width={500}
              alt="Codingal teachers"
            />
          </div>

          {!isCoursePage && !ctaLink && !ctaText && (
            <div className="flex flex-col lg:flex-row gap-4">
              {isShowBecomeTeacher && (
                <Link href="/teach/" prefetch={false}>
                  <a>
                    <Button variant="outline" className="w-full" size={ctaSize}>
                      <Trans id="homepage.teacherFold.becomeATeacher">
                        Become a teacher
                      </Trans>
                    </Button>
                  </a>
                </Link>
              )}
              <BookFreeClassButton
                size={ctaSize}
                shouldScrollToTop={shouldScrollToTop}
                query={urlQuery}
              />
            </div>
          )}
          {ctaLink && ctaText && (
            <div className="w-full md:max-w-xs text-center">
              <div className="flex justify-center md:justify-start">
                {' '}
                <a
                  className="btn btn-orange w-full md:w-auto py-3 text-lg"
                  href={ctaLink}
                >
                  {ctaText}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {isCoursePage && (
        <div className="mx-auto w-full md:max-w-xs text-center">
          <BookFreeClassBtn query={urlQuery} />
        </div>
      )}
    </div>
  )
}

export default Teachers
